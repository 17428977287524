<template>
    <div style="position: relative">
        <canvas ref="canvas" width="300" height="300" />
        <div id="hanged" v-if="showHangedLabel">
          HANGED!
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
export default {
  props: {
    isIntro: Boolean
  },
  setup(props,{emit}) {
    const canvas = ref(null)
    const canvasContext = ref(null)

    const stickmanLines = [
      {
        type: "line",
        lineWidth: 5,
        coords: [0,300,300,300]
      },
      {
        type: "line",
        lineWidth: 5,
        coords: [75,300,75,0]
      },
      {
        type: "line",
        lineWidth: 5,
        coords: [75,0,200,0]
      },
      {
        type: "line",
        lineWidth: 5,
        coords: [200,0,200,50]
      },
      {
        type: "circle",
        lineWidth: 3,
        coords: [200,75,25]
      },
      {
        type: "line",
        lineWidth: 3,
        coords: [200,100,200,180]
      },
      {
        type: "line",
        lineWidth: 3,
        coords: [200,180,170,250]
      },
      {
        type: "line",
        lineWidth: 3,
        coords: [200,180,230,250]
      },
      {
        type: "line",
        lineWidth: 3,
        coords: [170,140,230,140]
      },
    ]

    onMounted(() => {
      canvasContext.value = canvas.value.getContext('2d');
      canvasContext.value.lineWidth = 3
      if (props.isIntro) {
        stickmanLines.forEach((line) => {
          draw(line)
        })
        setTimeout(function () {
          showHangedLabel.value = true
        },1000)
      }
    })

    const draw = (line) => {
      let ctx = canvasContext.value
      if (line.type == "line") {
        drawLine(line)
      } else if (line.type == "circle") {
        drawCircle(line,0)
      }
    }

    let curPerc = 0

    let circle_interval = null

    const drawCircle = (line, current) => {
      // return
        let ctx = canvasContext.value
        ctx.beginPath();
        // ctx.lineWidth = line.lineWidth;
        // ctx.arc(line.coords[0], line.coords[1], line.coords[2],0,3590);
        ctx.arc(line.coords[0], line.coords[1], line.coords[2], -(Math.PI / 2), ((Math.PI * 2) * current) - Math.PI / 2, false);
        ctx.stroke();
        curPerc++;
        if (curPerc < 100) {
            circle_interval = setTimeout(function () {
                drawCircle(line, curPerc / 100)
            },1);
        }
        if (curPerc == 100) {
          curPerc = 0
          window.clearInterval(circle_interval);
        }
    }

    const drawLine = (line) => {
      let ctx = canvasContext.value
      let amount = 0
      let startX = line.coords[0]
      let startY = line.coords[1]
      let endX = line.coords[2]
      let endY = line.coords[3]
      // ctx.moveTo(startX,startY)
      // ctx.lineTo(endX,endY);
      // ctx.stroke()
      let map_int = setInterval(function() {
        amount += 0.05; // change to alter duration
        if (amount > 1) amount = 1;
        ctx.moveTo(startX, startY);
        // ctx.lineWidth = line.lineWidth;
        ctx.lineTo(startX + (endX - startX) * amount, startY + (endY - startY) * amount);
        ctx.stroke();
        if(amount == 1) window.clearInterval(map_int);
      }, 30);

    }

    const currentMistake = ref(0);

    const addMistake = () => {
      draw(stickmanLines[currentMistake.value])
      currentMistake.value++
    }

    const showHangedLabel = ref(false)

    watch(currentMistake, (val) => {
      if (val === stickmanLines.length) {
          showHangedLabel.value = true
          emit("set-gameover")
      } else {
          showHangedLabel.value = false
      }

      if (val == 0) {
        canvasContext.value.beginPath()
        canvasContext.value.moveTo(0,0)
        canvasContext.value.clearRect(0, 0, 300, 300);
      }
    })

    return {
      drawLine,
      addMistake,
      currentMistake,
      canvas,
      showHangedLabel
    }
  }
}
</script>

<style lang="scss" scoped>
#hanged {
  font-size: 60px;
  font-weight: bold;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(-30deg);
}
</style>
