<template lang="html">
  <div class="hangman-game-wrapper">
    <v-row>
      <v-col v-show="!gameStarted" cols="12" md="4" class="ml-auto mr-auto mt-5">
        <v-card width="400">
          <v-card-text class="text-center">
            <Stickman :is-intro="true"></Stickman>
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="error--text">Don't let yourself hang!</h2>
            <v-btn color="primary" large class="mt-5" @click="gameStarted = true">
              Start Game
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
    v-model="gameStarted"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    eager
    >
      <v-card tile>
        <v-toolbar
          dark
          color="primary"
          class="mb-8"
        >
          <v-btn
            icon
            dark
            @click="closeGame"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-scale-transition leave-absolute>
            <h2 v-if="showHint">{{hintLocal}}</h2>
          </v-scale-transition>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="showHint = true; stickmanRef.addMistake()"
              >
                <v-icon>{{ icons.mdiHelp }}</v-icon>
              </v-btn>
            </template>
            <span>Show hint? You will loose one attempt.</span>
          </v-tooltip>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12" md="4">

                <Stickman @set-gameover="keywordsRef.gameOver = true" :is-intro="false" ref="stickmanRef"></Stickman>

            </v-col>
            <v-col cols="12" md="8">

                <KeyWords ref="keywordsRef" @set-hint="(hint) => hintLocal = hint" @draw-line="stickmanRef.addMistake()" @refresh-stickman="stickmanRef.currentMistake = 0"></KeyWords>

            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import Stickman from './Stickman.vue'
import KeyWords from './KeyWords.vue'
import { mdiClose, mdiHelp } from '@mdi/js'

export default {
  components: {
    Stickman,
    KeyWords
  },
  setup() {

    const gameStarted = ref(false)

    const stickmanRef = ref()
    const keywordsRef = ref()
    const hintLocal = ref("")
    const showHint = ref(false)

    const closeGame = () => {
      gameStarted.value = false
    }

    return {
      gameStarted,
      stickmanRef,
      keywordsRef,
      closeGame,
      showHint,
      hintLocal,
      icons: {
        mdiClose,
        mdiHelp
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
